import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { BsCheckCircleFill } from "react-icons/bs"
const WhoWeAre = () => {
  return (
    <section className="py-5 container-fluid bg-gray">
      <div className="px-4">
        <div class="row">
          <div className="col-md-6">
            <StaticImage
              src="../images/mine-01.jpg"
              height="100%"
              quality={100}
              formats={["AUTO", "WEBP"]}
              alt="A Gatsby astronaut"
              className="img-fluid"
            />
          </div>
          <div className="col-md-6">
            <h6 className="small-headline text-dark">Who We Are</h6>
            <h1 className="hero-heading text-dark">
              We Provide highly{" "}
              <span className="text-primary">Credible expertise</span> and
              results for every project undertaken
            </h1>
            <p className="section-text text-gray-dark">
              Alom Mining and Geohydro Services are competent consultancy
              service providers and consultants in mining and groundwater
              development sectors in Uganda.
            </p>
            <p className="section-text text-gray-dark">
              We have experts and professionals in.
            </p>

            <ul className="">
              <li className="list-item">
                <BsCheckCircleFill className="icon" /> Business Strategy &
                Mining engineering
              </li>
              <li className="list-item">
                <BsCheckCircleFill className="icon" />
                Geology/Hydrogeology
              </li>
              <li className="list-item">
                <BsCheckCircleFill className="icon" />
                Petroleum
              </li>
              <li className="list-item">
                <BsCheckCircleFill className="icon" />
                Geophysics
              </li>
            </ul>
            <div className="d-flex cta-box">
              <li className="cta-link cta ">
                <Link to="/about">More About Us</Link>
              </li>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhoWeAre
