import * as React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import "bootstrap/dist/css/bootstrap.min.css"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import WhoWeAre from "../components/whoWeAre"
import ExpertiseIn from "../components/expertise"
import ProjectsBrief from "../components/projectsBrief"
import HomeCarousel from "../components/carousel"
// import Preloader from "../components/preloader"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <HomeCarousel />
    <Hero />
    <WhoWeAre />
    <ExpertiseIn />
    {/* <ProjectsBrief /> */}
  </Layout>
)

export default IndexPage
