import * as React from "react"
// import { Link } from "gatsby"
import {
  MdOutlineLocationOn,
  MdOutlinePhoneAndroid,
  MdOutlineMail,
} from "react-icons/md"

import Layout from "../components/layout"
import Seo from "../components/seo"

const SecondPage = () => (
  <Layout>
    <Seo title="Alom - Get In Touch" />
    <section className="container-fluid bg-primary">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <h1 className="hero-heading text-white">
              Let’s start a project together.
            </h1>
            <p className="section-text text-white">
              To be the leading consultancy firm in sustainable geo-resource
              development.
            </p>

            <hr />
            <div className="footer-icon-box">
              <div className="icon-bg">
                <MdOutlineLocationOn className="icon" />
              </div>
              <div className="icon-label">
                <p className="icon-title">Location</p>3 rd Floor, Afri Courts
                Building
                <p>Plot 107, Buganda Road P.O BOX 654, Entebbe</p>
              </div>
            </div>
            <hr />
            <div className="footer-icon-box ">
              <div className="icon-bg">
                <MdOutlinePhoneAndroid className="icon" />
              </div>
              <div className="icon-label">
                <p className="icon-title">Call Us</p>
                <a href="tel:+256755181424" className="text-white">
                  +256 755 181 424
                </a>
              </div>
            </div>
            <hr />
            <div className="footer-icon-box">
              <div className="icon-bg">
                <MdOutlineMail className="icon" />
              </div>
              <div className="icon-label">
                <p className="icon-title">Email Us</p>
                <a
                  href="mailto:info.alomgeomine@gmail.com?subject=Feedback&body=Message"
                  className="text-white"
                >
                  info.alomgeomine@gmail.com
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="mission-card bg-secondary">
              <h1 className="hero-heading text-white">
                Tell Us More About your Project
              </h1>
              <p className="section-text text-white">
                Have a project in mind, new business, or inquiry? Take your
                first step by choosing your preferred option and filling the
                form.One of Our Engineers will get in touch with you soon.
              </p>
              <form
                className="row g-3"
                id="contact-form"
                method="POST"
                action="https://formsubmit.co/757d5dd73f1d44f785766597cd115365"
              >
                <div className="col-md-6">
                  <label htmlFor="inputFullName" className="form-label">
                    Full Name
                  </label>
                  <input
                    type="text"
                    placeholder="Enter your full name"
                    name="fullname"
                    className="form-control"
                    id="inputFullName"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputEmail4" className="form-label">
                    Email Address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder="enter your email address"
                    id="inputEmail4"
                    required
                  />
                </div>
                <input
                  type="hidden"
                  name="_next"
                  value="https://www.alomgeomine.com/thankyou"
                />
                <input
                  type="hidden"
                  name="_subject"
                  value="New Client submission!"
                />
                {/* <!-- <input type="hidden" name="_captcha" value="false" /> --> */}
                <input
                  type="hidden"
                  name="_autoresponse"
                  value="We've received your message, someone from our team will contact you
              soon."
                />
                <input type="hidden" name="_template" value="table" />
                <div className="col-md-6">
                  <label htmlFor="inputPhone" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    placeholder="Enter your phone Number"
                    className="form-control"
                    id="inputPhone"
                    name="phone-number"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputBrand" className="form-label">
                    Enter your Company Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="enter your company Name"
                    id="inputBrand"
                    name="company-name"
                    required
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="subject" className="form-label">
                    Enter Subject
                  </label>
                  <input
                    type="text"
                    placeholder="Enter your subject here"
                    name="subject"
                    className="form-control"
                    id="subject"
                    required
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="request" className="form-label">
                    Tell Us about your Project
                  </label>
                  <textarea
                    className="form-control"
                    name="request-body"
                    id="request"
                    rows="3"
                    required
                    spellCheck="true"
                  ></textarea>
                </div>

                <div className="col-12 center">
                  <button type="submit" className="cta-btn">
                    Submit your Request
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="container-fluid bg-gray">
      <div className="container">
        <h1 className="hero-heading text-secondary text-center">
          Locate Us with Google Map.
        </h1>

        <div className="row">
          <div className="col-md-12">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.7521800215386!2d32.57320184826021!3d0.3299094997581285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x177dbb730aefe5a1%3A0xf7bc784e3671ac10!2sAfri%20Courts!5e0!3m2!1sen!2sug!4v1661430636660!5m2!1sen!2sug"
              width="100%"
              height="500"
              style={{ border: 0 }}
              allowFullScreen
              title="Alom mining company Location"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default SecondPage
