import React from "react"
import { AiOutlineCodeSandbox, AiOutlineTeam } from "react-icons/ai"
import { BsBriefcase } from "react-icons/bs"
import { FaRegHandshake } from "react-icons/fa"
import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutPage = () => (
  <Layout>
    <Seo title="Alom-About Us" />
    <div className="container-fluid bg-gray ">
      <div className="">
        <div class="row about-bg">
          <div className="col-md-6 bg-secondary opacity-90 p-4">
            <h6 className="small-headline text-white">About Us</h6>
            <h1 className="hero-heading text-white">
              Consultants in <span className="text-primary px-2">Mining</span>{" "}
              and
              <span className="text-primary px-2">Groundwater</span>
            </h1>
            <p className="section-text text-white">
              Alom Mining and Geohydro Services are competent consultancy
              service providers and consultants in mining and groundwater
              development sectors in Uganda.
            </p>
            <p className="section-text text-white">
              We are focused on providing highly credible expertise and results
              for every project undertaken. Our technical staff have advanced
              degrees and lengthy working experience in the operations hence our
              services are provided with utmost professional competence in line
              with technical and ethical quality.
            </p>
            <p className="section-text text-white">
              We have experts and professionals in mining engineering,
              geology/hydrogeology, petroleum, geophysics and many other related
              fields with extensive experience in exploration, mining and
              groundwater which is one of the reasons you should trust us for
              your mineral investment and groundwater projects.
            </p>
          </div>
        </div>
      </div>
    </div>
    <section className="container-fluid bg-primary">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="mission-card bg-secondary">
              <h1 className="hero-heading text-white">Our Mission</h1>
              <p className="section-text text-white">
                To be a model professional geo-resource consultancy company
                providing timely and financially efficient services to the
                satisfaction of all our clients.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mission-card bg-secondary">
              <h1 className="hero-heading text-white">Our Vision</h1>
              <p className="section-text text-white">
                To be the leading consultancy firm in sustainable geo-resource
                development.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <section class="container-fluid bg-client"> */}
    {/* <div className="bg-client-orange"></div> */}
    {/* <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="client-card">
              <div className="footer-icon-box  ">
                <div className="icon-bg bg-primary shadow">
                  <BsBriefcase className="icon text-white" />
                </div>
              </div>
              <h1 className="py-2">5+</h1>
              <h3 className="py-2">Years Of Experience</h3>
              <p className="section-text text-dark">
                The leaders in the industry with the decade of experience.
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="client-card">
              <div className="footer-icon-box ">
                <div className="icon-bg bg-primary shadow">
                  <FaRegHandshake className="icon text-white" />
                </div>
              </div>
              <h1 className="py-2">200+</h1>
              <h3 className="py-2">Satisfied Clients</h3>
              <p className="section-text text-dark">
                Clients satisfied with our work and still counting.
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="client-card">
              <div className="footer-icon-box ">
                <div className="icon-bg bg-primary">
                  <AiOutlineCodeSandbox className="icon text-white" />
                </div>
              </div>
              <h1 className="py-2">360</h1>
              <h3 className="py-2">Completed Projects</h3>
              <p className="section-text text-dark">
                Projects are executed beyond expectations.
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="client-card">
              <div className="footer-icon-box ">
                <div className="icon-bg bg-primary shadow">
                  <AiOutlineTeam className="icon text-white" />
                </div>
              </div>
              <h1 className="py-2">20</h1>
              <h3 className="py-2">Satisfied Engineers</h3>
              <p className="section-text text-dark">
                Our Team is filled with experts in the field .
              </p>
            </div>
          </div>
        </div>
      </div> */}
    {/* </section> */}
  </Layout>
)

export default AboutPage
