import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader

const Hero = () => {
  return (
    <section className="py-5 container-fluid bg-secondary">
      <div className="px-4">
        <div class="row">
          <div className="col-md-6">
            <h6 className="small-headline text-white">
              Welcome to Alom Geo Mine
            </h6>
            <h1 className="hero-heading text-white">
              We are Specialists in
              <span className="text-primary"> Mining </span>
              and
              <span className="text-primary"> Ground Water Development</span>
            </h1>
            <p className="section-text text-gray">
              As a professional company, we value sustainable geo-resource
              development, environmentally friendly methods of work, honesty and
              passion for work, customer satisfaction, offering workable
              solutions, sharing the pleasure of success together as well as
              professional excellence.
            </p>
            <div className="d-flex cta-box">
              <li className="cta-link cta ">
                <Link to="/consult">Get Consultation</Link>
              </li>
              <li className="cta-link cta ">
                <Link to="/about">More about Us</Link>
              </li>
            </div>
          </div>
          <div className="col-md-6">
            <StaticImage
              src="../images/mine-02.jpg"
              // height="100%"
              quality={100}
              formats={["AUTO", "WEBP"]}
              alt="A Gatsby astronaut"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
