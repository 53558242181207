import { BsCheck2Circle } from "react-icons/bs"
import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Thankyou = () => (
  <Layout>
    <Seo title="Thank You" />
    <div className="container text-center my-5">
      <div className="footer-icon-box center">
        <div className="icon-bg">
          <BsCheck2Circle className="icon" />
        </div>
      </div>
      <h1 className="py-4">Thank you</h1>
      <p>
        We've received your message, someone from our team will contact you
        soon.
      </p>
      <Link to="/">Go back to the homepage</Link>
    </div>
  </Layout>
)

export default Thankyou
