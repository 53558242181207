import React from "react"
import { Link } from "gatsby"
import { BsCheckCircleFill } from "react-icons/bs"
import { MdTravelExplore } from "react-icons/md"
import { FaLongArrowAltRight } from "react-icons/fa"
import { GiMining, GiWaterMill } from "react-icons/gi"
import { TbSignRight } from "react-icons/tb"
// import { services } from "../../data"
import { StaticImage } from "gatsby-plugin-image"
import { Carousel } from "react-bootstrap"
const ExpertiseIn = () => {
  return (
    <section className="py-5 container-fluid bg-gray">
      <div className="px-lg-4">
        <div className="row sub-section">
          <div className="col-md-12 ">
            <h6 className="small-headline text-dark">Our Services</h6>
          </div>
          <div className="col-md-8">
            <p className="section-text text-gray-dark">
              We are focused on providing highly{" "}
              <span className="text-primary">
                Credible expertise and results{" "}
              </span>{" "}
              for every project undertaken. Our technical staff have advanced
              degrees and lengthy working experience in the operations hence our
              services are provided with utmost professional competence in line
              with technical and ethical quality.
            </p>
          </div>
          <div className="col-md-4 ">
            <div className="d-flex cta-box">
              <li className="cta-link cta ">
                <Link to="/services">See All Services in Details</Link>
              </li>
            </div>
          </div>
        </div>
        <div className="row">
          {/* SERVICE 1: MINERAL EXPLORATION */}
          <div className="col-md-12 " id="mineralExplorations">
            <div className="service-card">
              <div className="service-card__header flex-between">
                <h3 className="service-card__title">
                  <Link
                    to="/"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#mineralExploration"
                  >
                    Mineral Exploration Services
                  </Link>
                </h3>
                <div className="service-card__icon">
                  <Link
                    to="/"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#mineralExploration"
                  >
                    <MdTravelExplore className="icon" />
                  </Link>
                </div>
              </div>
              <div className="service-card__body">
                <div className="row">
                  <div className="col-md-6">
                    <Link to="/" className="navbar-brand" href="/">
                      <Carousel fade>
                        <Carousel.Item>
                          <StaticImage
                            src="../images/Geochemistry.png"
                            quality={100}
                            formats={["AUTO", "WEBP"]}
                            alt="Geochemistry and Mineral exploration"
                          />
                          <Carousel.Caption>
                            <h3>Geochemistry</h3>
                          </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                          <StaticImage
                            src="../images/Geological mapping.png"
                            quality={100}
                            formats={["AUTO", "WEBP"]}
                            alt="Geological Mapping"
                          />
                          <Carousel.Caption>
                            <h3>Geological Mapping</h3>
                          </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                          <StaticImage
                            src="../images/exploration.jpg"
                            quality={100}
                            formats={["AUTO", "WEBP"]}
                            alt="Mineral Exploration"
                          />
                          <Carousel.Caption>
                            <h3>Mineral Exploration</h3>
                          </Carousel.Caption>
                        </Carousel.Item>
                      </Carousel>
                    </Link>
                  </div>
                  <div className="col-md-6">
                    <p className="pt-2 section-text text-secondary">
                      Mineral Exploration includes.
                    </p>
                    <ul className="">
                      <li className="list-item">
                        <BsCheckCircleFill className="icon" /> Geochemistry
                      </li>
                      <li className="list-item">
                        <BsCheckCircleFill className="icon" /> Geophysics
                      </li>
                      <li className="list-item">
                        <BsCheckCircleFill className="icon" /> Geological
                        Mapping
                      </li>
                      <li className="list-item">
                        <BsCheckCircleFill className="icon" /> RC & Core
                        Drilling
                      </li>
                    </ul>
                    {/* CTA */}
                    <div className="d-flex cta-box">
                      <li className="cta-link cta bg-secondary">
                        <Link to="/contact">Contact Us for this Service</Link>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service-card__footer">
                <div className="cta-link cta">
                  <Link
                    to="/"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#mineralExploration"
                    className="d-flex align-items-center"
                  >
                    View Details <FaLongArrowAltRight className="cta-icon" />
                  </Link>
                </div>
                {/* PROJECT MODAL */}
                <div
                  class="modal fade "
                  id="mineralExploration"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">
                          Mineral exploration services
                        </h5>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        <p className="section-text text-gray-dark">
                          We provide services and supervision of the entire
                          chain of exploration activities that includes;
                          archived data search, satellite imaginary data
                          acquisition, desktop studies, initial assessment and
                          appraisal for mineral exploration/target generation
                          for prospective areas, geological mapping, geochemical
                          sampling, sample testing and analysis/interpretation
                          of results, ground geophysics surveys, drilling (RAB &
                          diamond) and ore reserve estimations etc.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* SERVICE2 : MINING SERVICES */}
          <div className="col-md-12 " id="miningServices">
            <div className="service-card">
              <div className="service-card__header flex-between">
                <h3 className="service-card__title">
                  <Link
                    to="/about"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#miningService"
                  >
                    Mining Services
                  </Link>
                </h3>
                <div className="service-card__icon">
                  <Link
                    to="/about"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#miningService"
                  >
                    <GiMining className="icon" />
                  </Link>
                </div>
              </div>
              <div className="service-card__body">
                <div className="row">
                  <div className="col-md-6">
                    <Link
                      to="/"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#miningService"
                      className="navbar-brand"
                      href="/"
                    >
                      <Carousel fade>
                        <Carousel.Item>
                          <StaticImage
                            src="../images/Drilling and Blasting materials.jpg"
                            quality={100}
                            formats={["AUTO", "WEBP"]}
                            alt="ALOM Mining services"
                          />
                          <Carousel.Caption>
                            <h3>Drilling and blasting materials</h3>
                          </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                          <StaticImage
                            src="../images/underground.jpg"
                            quality={100}
                            formats={["AUTO", "WEBP"]}
                            alt="ALOM Mining and ground water development"
                          />
                          <Carousel.Caption>
                            <h3>Underground mine development</h3>
                          </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                          <StaticImage
                            src="../images/slides/slide-6.jpg"
                            quality={100}
                            formats={["AUTO", "WEBP"]}
                            alt="ALOM Mining and ground water development"
                          />
                          <Carousel.Caption>
                            <h3>Community and Stakeholder Engagements</h3>
                          </Carousel.Caption>
                        </Carousel.Item>
                      </Carousel>
                    </Link>
                  </div>
                  <div className="col-md-6">
                    <p className="pt-2 section-text text-secondary">
                      Mining Services includes.
                    </p>
                    <ul className="">
                      <li className="list-item">
                        <BsCheckCircleFill className="icon" /> Mine Design and
                        Development
                      </li>
                      <li className="list-item">
                        <BsCheckCircleFill className="icon" /> Management of
                        Mines and Assets
                      </li>
                      <li className="list-item">
                        <BsCheckCircleFill className="icon" /> Mining Community
                        & Stakeholder Engagements
                      </li>
                    </ul>
                    {/* CTA */}
                    <div className="d-flex cta-box">
                      <li className="cta-link cta bg-secondary">
                        <Link to="/contact">Contact Us for this Service</Link>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service-card__footer">
                <div className="cta-link cta">
                  <Link
                    to="/about"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#miningService"
                    className="d-flex align-items-center"
                  >
                    View Details <FaLongArrowAltRight className="cta-icon" />
                  </Link>
                </div>
                {/* PROJECT MODAL */}
                <div
                  class="modal fade "
                  id="miningService"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">
                          Mining services
                        </h5>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        <p className="section-text text-gray-dark">
                          Provision of pre-feasibility studies for initial mine
                          designs and development, drilling and blasting
                          services, underground mine development, advisory
                          support and mining operations management, mine design
                          done with modern and up-to-date technology and
                          software such as SURPAC, ARCGIS, WHITTLE, GPSSH and
                          DATAMINE that are used to optimize mining operations
                          and finances. Provisions of mineral processing
                          techniques for the best ore recovery in most economic
                          and safe way possible. Technical mine development
                          services with a specialization in small to medium
                          scale underground mining. Underground and surface mine
                          design for cost effective and safe mining. Mine method
                          selection; fleet size, material handling, mine
                          ventilation and support etc.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* SERVICE3 : MINERAL RIGHT ADMIN */}
          <div className="col-md-12 " id="mineralRightAdmins">
            <div className="service-card">
              <div className="service-card__header flex-between">
                <h3 className="service-card__title">
                  <Link
                    to="/about"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#mineralRightAdmin"
                  >
                    Mineral Rights Administration
                  </Link>
                </h3>
                <div className="service-card__icon">
                  <Link
                    to="/about"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#mineralRightAdmin"
                  >
                    <TbSignRight className="icon" />
                  </Link>
                </div>
              </div>
              <div className="service-card__body">
                <div className="row">
                  <div className="col-md-6">
                    <Link
                      to="/"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#mineralRightAdmin"
                      className="navbar-brand"
                      href="/"
                    >
                      <Carousel fade>
                        <Carousel.Item>
                          <StaticImage
                            src="../images/government.jpg"
                            height={"100%"}
                            quality={100}
                            formats={["AUTO", "WEBP"]}
                            alt="Exploration services"
                            className="img-fluid"
                          />
                          <Carousel.Caption>
                            <h3>
                              Government Officials Inspection of one of our
                              sites in Kitgum district{" "}
                            </h3>
                          </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                          <StaticImage
                            src="../images/slides/slide-5.png"
                            quality={100}
                            formats={["AUTO", "WEBP"]}
                            alt="ALOM Mining and ground water development"
                          />
                          <Carousel.Caption>
                            <h3 className="text-dark">
                              Exploration License Acquisition
                            </h3>
                          </Carousel.Caption>
                        </Carousel.Item>
                      </Carousel>
                    </Link>
                  </div>
                  <div className="col-md-6">
                    <p className="pt-2 section-text text-secondary">
                      Mineral Right administration includes.
                    </p>
                    <ul className="">
                      <li className="list-item">
                        <BsCheckCircleFill className="icon" /> Acquisition of
                        Prospecting, Exploration and Location licenses
                      </li>
                      <li className="list-item">
                        <BsCheckCircleFill className="icon" /> Acquisition of
                        Mining Leases
                      </li>
                      <li className="list-item">
                        <BsCheckCircleFill className="icon" /> Compliance
                        Assistance to Mining Laws & Regulations
                      </li>
                    </ul>
                    {/* CTA */}
                    <div className="d-flex cta-box">
                      <li className="cta-link cta bg-secondary">
                        <Link to="/contact">Contact Us for this Service</Link>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service-card__footer">
                <div className="cta-link cta">
                  <Link
                    to="/about"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#mineralRightAdmin"
                    className="d-flex align-items-center"
                  >
                    View Details <FaLongArrowAltRight className="cta-icon" />
                  </Link>
                </div>
                {/* PROJECT MODAL */}
                <div
                  class="modal fade "
                  id="mineralRightAdmin"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">
                          Mineral Rights Administration
                        </h5>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        <p className="section-text text-gray-dark">
                          We provide services for the process of acquisition of
                          various mineral rights/licenses in Uganda e.g.,
                          prospecting, exploration and location licenses as well
                          as mining leases. We also take up the respective
                          compliance obligations as required by the Mining laws
                          and regulations of Uganda..
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* SERVICE 4: GROUND WATER DEVELOPMENT */}
          <div className="col-md-12 " id="groundWaterDevs">
            <div className="service-card">
              <div className="service-card__header flex-between">
                <h3 className="service-card__title">
                  <Link
                    to="/about"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#groundWaterDev"
                  >
                    Geo-hydro/Groundwater Development
                  </Link>
                </h3>
                <div className="service-card__icon">
                  <Link
                    to="/about"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#groundWaterDev"
                  >
                    <GiWaterMill className="icon" />
                  </Link>
                </div>
              </div>
              <div className="service-card__body">
                <div className="row">
                  <div className="col-md-6">
                    <Link
                      to="/"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#groundWaterDev"
                      className="navbar-brand"
                      href="/"
                    >
                      <Carousel fade>
                        <Carousel.Item>
                          <StaticImage
                            src="../images/groundwater.jpg"
                            height={"100%"}
                            quality={100}
                            formats={["AUTO", "WEBP"]}
                            alt="Exploration services"
                            className="img-fluid"
                          />
                          <Carousel.Caption>
                            <h3>Groundwater development</h3>
                          </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                          <StaticImage
                            src="../images/water-2.jpg"
                            quality={100}
                            formats={["AUTO", "WEBP"]}
                            alt="ALOM Mining and ground water development"
                          />
                          <Carousel.Caption>
                            <h3>Underground water development</h3>
                          </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                          <StaticImage
                            src="../images/slides/slide-6.jpg"
                            quality={100}
                            formats={["AUTO", "WEBP"]}
                            alt="ALOM Mining and ground water development"
                          />
                          <Carousel.Caption>
                            <h3>Community and Stakeholder Engagements</h3>
                          </Carousel.Caption>
                        </Carousel.Item>
                      </Carousel>
                    </Link>
                  </div>
                  <div className="col-md-6">
                    <p className="pt-2 section-text text-secondary">
                      Geo-hydro/Groundwater development includes.
                    </p>
                    <ul className="">
                      <li className="list-item">
                        <BsCheckCircleFill className="icon" /> Groundwater
                        Surveys & Assessments
                      </li>
                      <li className="list-item">
                        <BsCheckCircleFill className="icon" /> Borehole Drilling
                        and Construction
                      </li>
                      <li className="list-item">
                        <BsCheckCircleFill className="icon" /> Pumping Test and
                        Borehole Installation
                      </li>
                    </ul>
                    {/* CTA */}
                    <div className="d-flex cta-box">
                      <li className="cta-link cta bg-secondary">
                        <Link to="/contact">Contact Us for this Service</Link>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service-card__footer">
                <div className="cta-link cta">
                  <Link
                    to="/about"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#groundWaterDev"
                    className="d-flex align-items-center"
                  >
                    View Details <FaLongArrowAltRight className="cta-icon" />
                  </Link>
                </div>
                {/* PROJECT MODAL */}
                <div
                  class="modal fade "
                  id="groundWaterDev"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">
                          Geohydro/Groundwater Development
                        </h5>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        <p className="section-text text-gray-dark">
                          Provision of regional and local scale groundwater
                          investigations and assessments, water well drilling
                          and supervisions, aquifer assessment and evaluation
                          (pumping test), water quality assessments, aquifer
                          pollution vulnerability and susceptibility assessments
                          and managing groundwater-based water supply schemes.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ExpertiseIn
