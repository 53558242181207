import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { BsCheckCircleFill } from "react-icons/bs"
import Layout from "../components/layout"
import Seo from "../components/seo"
const ProjectsBrief = () => {
  return (
    <Layout>
      <Seo title="Alom - Projects" />
      <section className="py-5 container-fluid bg-gray">
        <div className="px-4">
          <div className="row sub-section">
            <div className="col-md-12 ">
              <h6 className="small-headline text-dark">Our Projects</h6>
            </div>
            <div className="col-md-8">
              <p className="section-text text-gray-dark">
                We have{" "}
                <span className="text-primary">Experts and professionals </span>{" "}
                in many fields with extensive experience in exploration, mining
                and groundwater which is one of the reasons you should trust us
                for your mineral investment and groundwater projects.
              </p>
              <h1 className="hero-heading text-dark ">
                We have worked on a number of{" "}
                <span className="text-primary ">Projects</span> within the
                country among which include the following.
              </h1>
            </div>
            <div className="col-md-4 ">
              <div className="d-flex cta-box">
                <li className="cta-link cta bg-secondary">
                  <Link to="/contact">Start a Project With Us</Link>
                </li>
              </div>
            </div>
          </div>
          {/* PROJECT ONE */}
          <div class="row project">
            <div className="col-md-6">
              <StaticImage
                src="../images/mine-04.jpg"
                height="100%"
                quality={100}
                formats={["AUTO", "WEBP"]}
                alt="A Gatsby astronaut"
                className="img-fluid"
              />
            </div>
            <div className="col-md-6 ">
              <h1 className="hero-heading text-dark">
                Consolidated African Resources (CONSAF)
              </h1>
              <h6 className="small-headline text-dark">Work Performed</h6>
              <ul className="">
                <li className="list-item">
                  <BsCheckCircleFill className="icon" />
                  Exploration work (in progress)
                </li>
                <li className="list-item">
                  <BsCheckCircleFill className="icon" />
                  Exploration license acquisition
                </li>
                <li className="list-item">
                  <BsCheckCircleFill className="icon" />
                  Surface rights acquisition, social and environmental plans
                  leading to grant of mining lease (work in progress).
                </li>
              </ul>
              <div className="d-flex cta-box">
                <li className="cta-link cta ">
                  <Link to="/about">Read More</Link>
                </li>
              </div>
            </div>
          </div>

          {/* PROJECT TWO */}
          <div class="row project">
            <div className="col-md-6">
              <h1 className="hero-heading text-dark">
                Acholi Resources Uganda Limited
              </h1>
              <h6 className="small-headline text-dark">Work Performed</h6>
              <ul className="">
                <li className="list-item">
                  <BsCheckCircleFill className="icon" />
                  Exploration license acquisition.
                </li>
                <li className="list-item">
                  <BsCheckCircleFill className="icon" />
                  Demarcation of licensed area by erection of corner beacons.
                </li>
                <li className="list-item">
                  <BsCheckCircleFill className="icon" />
                  Implemented the initial exploration program for gold
                  exploration in the license involving geochemical sampling,
                  ground geophysics (magnetics) and RAB drilling.
                </li>
              </ul>
              <div className="d-flex cta-box">
                <li className="cta-link cta ">
                  <Link to="/about">Read More</Link>
                </li>
              </div>
            </div>
            <div className="col-md-6">
              <StaticImage
                src="../images/mine-03.jpg"
                height="100%"
                quality={100}
                formats={["AUTO", "WEBP"]}
                alt="A Gatsby astronaut"
                className="img-fluid"
              />
            </div>
          </div>
          {/* PROJECT THREE */}
          <div class="row project">
            <div className="col-md-6">
              <StaticImage
                src="../images/mine-05.jpg"
                height="100%"
                quality={100}
                formats={["AUTO", "WEBP"]}
                alt="A Gatsby astronaut"
                className="img-fluid"
              />
            </div>
            <div className="col-md-6">
              <h1 className="hero-heading text-dark">Hima Cement Uganda Ltd</h1>
              <h6 className="small-headline text-dark">Work Performed</h6>
              <ul className="">
                <li className="list-item">
                  <BsCheckCircleFill className="icon" />
                  Decommissioning plan of the mined-out area
                </li>
                <li className="list-item">
                  <BsCheckCircleFill className="icon" />
                  Environmental Impact Assessment in Rwimi and Kasese areas
                </li>
                <li className="list-item">
                  <BsCheckCircleFill className="icon" />
                  Reconnaissance survey for occurrence of locked pebbles and
                  lime stone deposits in the whole of Uganda.
                </li>
              </ul>
              <div className="d-flex cta-box">
                <li className="cta-link cta ">
                  <Link to="/about">Read More</Link>
                </li>
              </div>
            </div>
          </div>
          {/* PROJECT FOUR */}
          <div class="row project">
            <div className="col-md-6">
              <h1 className="hero-heading text-dark">
                Goodwill Uganda Limited
              </h1>
              <h6 className="small-headline text-dark">Work Performed</h6>
              <ul className="">
                <li className="list-item">
                  <BsCheckCircleFill className="icon" />
                  Feasibility studies for feldspar mining project
                </li>
                <li className="list-item">
                  <BsCheckCircleFill className="icon" />
                  Prepared Mine Plan and designs
                </li>
                <li className="list-item">
                  <BsCheckCircleFill className="icon" />
                  Processed Mining Lease at DGSM
                </li>
              </ul>
              <div className="d-flex cta-box">
                <li className="cta-link cta ">
                  <Link to="/about">Read More</Link>
                </li>
              </div>
            </div>
            <div className="col-md-6">
              <StaticImage
                src="../images/mine-06.jpg"
                height="100%"
                quality={100}
                formats={["AUTO", "WEBP"]}
                alt="A Gatsby astronaut"
                className="img-fluid"
              />
            </div>
          </div>
          {/* PROJECT FIVE */}
          <div class="row project">
            <div className="col-md-6">
              <StaticImage
                src="../images/mine-07.jpg"
                height="100%"
                quality={100}
                formats={["AUTO", "WEBP"]}
                alt="A Gatsby astronaut"
                className="img-fluid"
              />
            </div>
            <div className="col-md-6">
              <h1 className="hero-heading text-dark">Nyamuriro Krone Mines</h1>
              <h6 className="small-headline text-dark">Work Performed</h6>
              <ul className="">
                <li className="list-item">
                  <BsCheckCircleFill className="icon" />
                  Mining plan, designs and operations review
                </li>
                <li className="list-item">
                  <BsCheckCircleFill className="icon" />
                  Feasibility report and business plan reviews
                </li>
              </ul>
              <div className="d-flex cta-box">
                <li className="cta-link cta ">
                  <Link to="/about">Read More</Link>
                </li>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ProjectsBrief
