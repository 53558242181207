import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import Carousel from "react-bootstrap/Carousel"
import "react-awesome-slider/dist/styles.css"
const HomeCarousel = () => {
  return (
    <div className="row ">
      <div className="col-md-12 bg-secondary">
        <Carousel fade>
          <Carousel.Item>
            <StaticImage
              src="../images/slides/slide-1.jpg"
              quality={100}
              formats={["AUTO", "WEBP"]}
              alt="Mineral Exploration Services"
            />
            <Carousel.Caption>
              <h3>Mineral Exploration Services</h3>
              <p>
                Exploration of gold and base metals in Kabarole district in
                Uganda.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <StaticImage
              src="../images/slides/slide-2.jpg"
              quality={100}
              formats={["AUTO", "WEBP"]}
              alt="Alom Mining and ground water development"
            />
            <Carousel.Caption>
              <h3> Mining Services</h3>
              <p>Underground Mine Development</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <StaticImage
              src="../images/slides/slide-3.jpg"
              quality={100}
              formats={["AUTO", "WEBP"]}
              alt="Mining Services"
            />
            <Carousel.Caption>
              <h3>Mining Services</h3>
              <p>Mineral Processing Techniques for best ore recovery</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <StaticImage
              src="../images/slides/slide-4.jpg"
              quality={100}
              formats={["AUTO", "WEBP"]}
              alt="Alom Mining and ground water development"
            />
            <Carousel.Caption>
              <h3>Groundwater Development</h3>
              <p>Well Water Pumping</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <StaticImage
              src="../images/slides/slide-5.webp"
              quality={100}
              formats={["AUTO", "WEBP"]}
              alt="Mineral Rights Administration in Uganda"
            />
            <Carousel.Caption>
              <h3> Mineral Rights Administration in Uganda</h3>
              <p>
                Government Officials Inspection of one of our sites in Kitgum
                district and Exploration License Acquisition
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <StaticImage
              src="../images/slides/slide-6.jpg"
              quality={100}
              formats={["AUTO", "WEBP"]}
              alt="Alom Mining and ground water development"
            />
            <Carousel.Caption>
              <h3>Mining services</h3>
              <p>Community and Stakeholder Engagements</p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  )
}

export default HomeCarousel
